import PropTypes from 'prop-types';
import { Typography } from 'antd';

const { Title: TitleAntd } = Typography;

const Title = ({ children, level, textTransform, align }) => {
  return (
    <TitleAntd className={`title ${align} ${textTransform}`} level={level}>
      {children}
    </TitleAntd>
  );
};

Title.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  textTransform: PropTypes.string,
  level: PropTypes.number,
  align: PropTypes.string
};
Title.defaultProps = {
  level: 4,
  textTransform: 'first-letter',
  align: 'left'
};

export default Title;
